import React from 'react';

import termGif from './t100.gif';

export default function HiddenJoy() {
  return (
    <div>
      <img src={termGif} width="100%" height="100%" alt="Terminator" />
    </div>
  );
}
